<template>
    <div class="scratch-container">
        <div class="area">

            <MainArea ref="main"></MainArea>

        </div>

        <div id="blockly-code-generator" class="blockly-code-generator" style="display: none"></div>

        <Loading />

    </div>
</template>

<script>
    import MainArea from './children/MainArea';
    // import Bottom from './children/Bottom';
    import Loading from '@/views/components/mask/Loading';

    import Ide from '@/libs/ide/ide.js';
    import CoreData from '@/libs/runtime/coreData.js';
    import qs from 'qs'
    import cos from '@/utils/cos.js'
    import "@/libs/index.js";
    import { mapGetters } from 'vuex'

    // common
    import { getCourseInfo, getSectionInfo } from '@/utils/common'
    // web/admin同名，但需要区分的接口
    import { getClassroomData } from '@/api/admin'
    // web
    import { saveProjectCover, sendReleaseMsg } from '@/api/web'
    // admin
    import { getAdminSectionData, saveAdminSectionRes } from '@/api/admin'

    export default {
        name: 'game-python',
        data: function() {
            return {
                isClassroom: false,
            }
        },
        // 不知道为啥没有调用成功？
        // 但是暂时离开页面有提示，先用着，后面排查
        beforeRouteLeave: function(to, from , next){
            let value = window.confirm("确定要离开当前页面吗？");
            if (value == true) {
                // 确定
                next()
            } else {
                // 取消
                next(false)
            }
        },
        computed: {
            ...mapGetters([
                'ide',
                'dataAreaData',
                'actorAreaData',
                'curActor',
                'pid',
                'cid',
                'sid',
                'userSectionId',
                'current',
            ]),
        },
        created() {
        },
        mounted() {
            // 加载userInfo
            this.loadUserInfo();

            // 根据path和id/cid/sid判断加载什么数据
            let pid = ''
            let sid = ''
            let cid = ''
            if (this.$route.query.pid) {
                pid = this.$route.query.pid
            }
            if (this.$route.query.cid) {
                cid = this.$route.query.cid
            }
            if (this.$route.query.sid) {
                sid = this.$route.query.sid
            }

            let path = this.$route.path
            if (path == '/iframe/classroom/python-game') {
                this.isClassroom = true
                this.loadClassroomData(cid, sid)

            } else if (path == '/iframe/editor/python-game') {
                this.isEditor = true
                this.loadEditorData(cid, sid)
                
            } else if (path == '/iframe/ide/python-game') {
                this.isIde = true
                this.loadProjectData(pid)
            }
        },
        destroyed() {
        },
        activated() {
        },
        methods: {
            getCourseInfo,
            getSectionInfo,
            async loadUserInfo() {
                await this.$store.dispatch('user/getUserInfo');
            },
            async loadClassroomData(cid, sid) {
                this.$store.commit('web/SetCourseId', cid)
                this.$store.commit('web/SetSectionId', sid)

                let resId = ''
                let toolbox = ''
                let res = await getClassroomData({ cid, sid })
                if (res && res.data) {
                    if (res.data.resId) {
                        resId = res.data.resId
                    }
                    if (res.data.userResId) {
                        this.userResId = res.data.userResId
                    }
                    if (res.data.toolbox) {
                        toolbox = res.data.toolbox
                    }
                }

                // 加载上课信息
                this.getCourseInfo(cid)

                // 加载小节信息
                this.getSectionInfo(sid)

                // 展示课程目标示例
                this.$store.commit('web/SetCurrent', 'demo')

                // 加载上课数据
                this.$store.dispatch('web/LoadClassroomData', {
                    resId: resId,
                    userResId: this.userResId,
                    toolbox: toolbox, 
                });
            },
            async loadEditorData(cid, sid) {
                this.$store.commit('web/SetCourseId', cid)
                this.$store.commit('web/SetSectionId', sid)

                let res = await getAdminSectionData({ cid, sid })
                if (res && res.data) {
                    this.userResId = res.data.resId
                
                    this.$store.commit('web/SetProjectId', this.userResId)
                }
                // 加载作品数据
                this.$store.dispatch('web/LoadProjectData', this.userResId)

                // 加载小节信息
                this.getSectionInfo(sid)

                // 展示用户作品
                this.$store.commit('web/SetCurrent', 'user')
            },
            loadProjectData(pid) {
                this.$store.commit('web/SetProjectId', pid)

                // 加载Project信息
                this.$store.dispatch('web/GetProjectInfo', pid)

                // 展示用户作品
                this.$store.commit('web/SetCurrent', 'user')

                // 加载作品数据
                this.$store.dispatch('web/LoadProjectData', pid)
            },
            selectSprite(id) {
                this.$refs.main.selectSprite(id);
            },
            deleteSprite(id) {
                this.$refs.main.deleteSprite(id);
            },
            // 切换舞台
            reloadStage(nav) {
                this.$refs.main.reloadStage(nav);
            },
            handleCmd(data) {
                switch (data.cmd) {
                    case 'save':
                        this.save()
                        break
                    case 'release':
                        this.release()
                        break
                    case 'switchCode':
                        this.switchCode(data.params)
                        break
                    case 'toggleData':
                        this.toggleData()
                        break
                    case 'toggleShowDemoCode':
                        this.toggleShowDemoCode()
                        break
                }
            },
            // 上传完成后回调：
            uploadDoneCallback(newCover) {
                // 更新数据库，保存新的coverId
                saveProjectCover({
                    id: this.pid,
                    newCover: newCover
                });
            },
            async save() {
                // 保存截屏
                let cover = window.stageInstance.screenshot();
                // console.log(cover);

                // 想改成直接在前端保存，没成功
                // 好像是base64解码的地方有问题

                // // preg_match('/^(data:\s*image\/(\w+);base64,)/', $cover, $result)
                // let coverData = cover.replace(/^data:image\/(\w*);base64,/, '');
                // // console.log(coverData);
                // let img = window.atob(coverData)
                // console.log(img)
                // await qiniu.uploadFile(img, 'covers', this.uploadDoneCallback)

                await saveProjectCover(qs.stringify({
                    pid: this.pid,
                    cover: cover
                }));

                // 保存项目数据
                CoreData.saveBlock(this.curActor.id);
                let data = CoreData.getSaveData('user');

                let id = ''
                if (this.isClassroom) {
                    id = this.userSectionId
                } else {
                    id = this.pid
                }

                if (id != '') {
                    await cos.putObject('project', id, JSON.stringify(data));
                }
            },
            async release() {
                this.save()

                await sendReleaseMsg({
                    type: 'project',
                    category: 'scratch',
                    id: this.isClassroom ? this.userSectionId : this.pid,
                    cid: ''
                });
            },
            switchCode(params) {
                // 更新一下代码区内容
                Ide.workspace2code();
                // 切换代码区显示
                this.$store.commit('web/ShowArea', { name: 'CODE_AREA', isShow: params.showCode });
            },
            toggleData() {
                let isShow = !this.ide.area.isShowDataArea;
                this.$store.commit('web/ShowArea', { name: 'DATA_AREA', isShow: isShow });

                if (isShow && this.dataAreaData.length == 0) {
                    // 展示初始化的数据区变量
                    this.$store.commit('web/InitDataAreaData');
                }
            },
            toggleShowDemoCode() {
                this.$store.commit('web/ToggleShowDemoCode');
            },
        },
        components: {
            MainArea,
            // Bottom,
            Loading,
        }
    }
</script>


<style lang="scss">
// 用于隐藏iframe区中的上下滚动条
html, body {
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.scratch-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .area {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        z-index: 100;
    }

//   // 隐藏积木区横向&纵向滚动条
//   .blocklyScrollbarHandle {
//     opacity: 0;
//   }
}
</style>
