<template>

    <div class="page-container">
        
        <Header @sendCmd="sendCmd"></Header>

        <Page ref="page" />
        
    </div>

</template>

<script>
import Header from './Header';
import Page from './Python'

export default {
    name: 'game-python-page',
    data: function() {
        return {
           //
        }
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    methods: {
        sendCmd(data) {
            this.$refs.page.handleCmd(data)
        },
    },
    components: {
        Header,
        Page
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #222;
}
</style>
