<template>
    <div class="koocoding-header">
        <div class="left-side">
            <div class="nav">
                <div class="return" @click.stop="goBack()">
                    <img src="@/assets/images/ide/return.svg">
                </div>
            </div>
        </div>
        <div class="middle-side">
            <div class="name">深藏的宝石</div>
        </div>
        <div class="right-side">
            <div class="login">
                <div class="avatar"><img :src="userInfo.avatar"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import screenfull from 'screenfull';

    import { mapGetters } from 'vuex'
    import { saveProjectName } from '@/api/web'
    import { goBack } from '@/utils/util'

    export default {
        name: 'ideHeader',
        data: function () {
            return {
                // 多语言
                lang: {
                    "zh-cn": "简体中文",
                    "zh-tw": "繁体中文",
                    "en": "English",
                },

                showCode: false,

                // 正在保存代码
                isSaving: false,
                // 正在发布代码
                isReleasing: false,

                // 定时器
                intervalAutoSave: null,
            }
        },
        computed: {
            ...mapGetters([
                'ide',
                'dataAreaData',
                'curActor',
                'pid',
                'cid',
                'projInfo',
                'curCodeType',
                'curLang',
                'userInfo',
                'topMsg',
                'current',
            ]),
        },
        created() {
        },
        mounted() {
            // 启动定时保存
            // this.intervalAutoSave = setInterval(this.autoSave, 15000);
        },
        beforeDestroy() {
            if (this.intervalAutoSave) {
                clearInterval(this.intervalAutoSave);
            }
        },
        activated() {
        },
        watch: {
            showCode: function() {
                this.switchCode();
            },
        },
        methods: {
            goBack,
            focusInput(e) {
                e.currentTarget.select();
            },
            projectNameChanged(e) {
                const { value } = e.target;
                let projName = value
                saveProjectName({ pid: this.pid, name: projName });
            },

            // 按钮命令
            save() {
                this.$emit('sendCmd', { cmd: 'save', params: {}});
            },
            release() {
                this.$emit('sendCmd', { cmd: 'release', params: {}});
            },
            switchCode() {
                this.$emit('sendCmd', { cmd: 'switchCode', params: { showCode: this.showCode }});
            },
            toggleData() {
                this.$emit('sendCmd', { cmd: 'toggleData', params: {}});
            },

            toggleScreenfull() {
                if (screenfull.isEnabled) {
                    screenfull.toggle();
                }
            },
            async autoSave() {
                this.save();
            },
            
        },
        components: {
        }
    }
</script>

<style lang="scss">
/** 用于调整v-switch中文字的大小和颜色 */
.show-code {
    .v-label {
        font-size: 13px !important;
        color: #eee !important;
    }
    padding: 0 15px;
}
</style>

<style lang="scss" scoped>
.koocoding-header {
    height: 70px;
    width: 100%;
    background: transparent url('~@/assets/images/game/hud_wood_background.png') repeat;
    background-size: 100% 100%;
    background-color: #23222b;

    display: flex;
    justify-content: space-between;
    color: #f6f6f6;
    font-size: 15px;
    z-index: 104;
    position: relative;

    .left-side {
        height: 100%;
        display: flex;
        //justify-content: space-between;
        justify-content: flex-start;
        align-items: center;

        .nav {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .return {
                height: 100%;
                width: 50px;
                display: flex;
                align-items: center;
                //border-left: 1px solid #000000;
                // border-right: 1px solid #4e5154;
                justify-content: center;
                align-items: center;
                z-index: 10;
                cursor: pointer;

                img {
                    height: 18px;
                    // margin-top: 5px;
                }
            }
        }
    }

    .middle-side {
        position: fixed;
        height: 60px;
        top: 0;
        left: calc(50% - 100px);
        // right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #ffffff;
        padding: 0 30px;
        min-width: 200px;
        max-width: 320px;

        background: url('~@/assets/images/game/header_name_background.png') no-repeat;
        background-size: 100% 100%;

        .name {
            color: #efefef;
            font-size: 18px;
            font-weight: bold;
            // background: url('~@/assets/images/game/header_name_background.png') no-repeat;
            // background-size: 100% 100%;
        }
    }

    .right-side {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        //border-left: 1px solid #4e5154;

        // 目前只保留avatar头像
        .login {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 15px 0 15px;
            position: relative;
            z-index: 10;
            cursor: pointer;

            .avatar {
                padding-top: 3px;

                img {
                    height: 36px;
                    width: 36px;
                    border: 2px solid white;
                    border-radius: 35px;
                }
            }
        }
    }
}
</style>
