<template>
    <div class="main-area forV">
        <div id="left-area" class="left-area" ref="leftArea">
            <div id="player-area" ref="playerArea" class="player-area">
                <div id="egret-player" class="egret-player"
                        data-entry-class="Main"
                        data-orientation="auto"
                        data-scale-mode="fixedWidth"
                        data-frame-rate="30"
                        data-content-width="100%"
                        data-content-height="100%"
                        data-show-paint-rect="false"
                        data-multi-fingered="2"
                        data-show-fps="false" data-show-log="false"
                        data-show-fps-style="x:0,y:0,size:12,textColor:0xffffff,bgAlpha:0.9">
                </div>
            </div>
            <div class="cmd-area"></div>
            <div class="property-area"></div>
        </div>

        <div id="left-right-border" class="left-right-border forV"></div>

        <div class="middle-area">
            <div class="category-group">
                <div class="group-title">函数</div>
                <div class="group-content">
                    <v-tooltip left 
                        content-class="custom-tooltip"
                        :open-on-hover=true
                        close-delay="0"
                        open-delay="200"
                        v-for="item in methodGroup"
                        :key="item.name"
                        z-index="1000"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div class="group-item" 
                                v-bind="attrs" 
                                v-on="on"
                                @click.stop="insertText(item.name)"
                            >
                                {{ item.name }}
                            </div>
                        </template>
                        <v-card class="item-desc">
                            <v-card-text>
                                <div class="desc-title">
                                    {{ item.name }} <span>方法</span>
                                </div>
                                <div class="desc-content">
                                    {{ item.desc }}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tooltip>
                </div>
            </div>
        </div>

        <div id="right-area" class="right-area">
            <div id="code-area" class="code-area" ref="blocklyArea" >

                <div class="code-background"></div>

                <div id="code-editor" class="code-editor"></div>

                <!-- <div
                    id="terminal"
                    class="terminal"
                    readonly="readonly"
                ></div> -->

            </div>

            <div class="btn-area">
                <div class="btn-run" @click.stop="play">运行</div>
                <div class="btn-done">完成</div>
            </div>
        </div>

        <!--素材库窗口-->
        <MediaLib/>

        <!--属性设置及添加造型&声音窗口-->
        <Configure/>

    </div>
</template>

<script>
    import $ from "jquery";

    import CoreData from '@/libs/runtime/coreData.js';
    import Ide from '@/libs/ide/ide.js';

    import { Terminal } from 'xterm';
    import 'xterm/css/xterm.css';
    import { FitAddon } from "xterm-addon-fit";

    // 不要删除，Play中用到了！！！
    import CodeInterpreter from '@/libs/runtime/codeInterpreter.js';
    import SoundInstance from '@/libs/common/sound.js';

    // 引导类，用于展示区吸取颜色
    import Driver from "driver.js";
    import "driver.js/dist/driver.min.css";

    import Configure from '@/views/components/window/Configure';
    import MediaLib from '@/views/components/window/MediaLib';

    import { mapGetters } from 'vuex';
    import { isEmptyObject,getImgUrl2 } from '@/utils/util'

    import ace from "ace-builds";
    import "ace-builds/webpack-resolver"; // 在 webpack 环境中使用必须要导入
    import "ace-builds/src-min-noconflict/theme-xcode"; // 默认设置的主题
    import "ace-builds/src-min-noconflict/mode-python"; // 默认设置的语言模式
    import "ace-builds/src-min-noconflict/ext-language_tools"; // 自动补全

    /* eslint-disable no-undef */

    export default {
        name: 'mainArea',
        props: {
            // showToolbox: true,
        },
        data: function() {
            return {
                showHistoryBack: false,
                showHistoryForward: false,

                leftAreaWidth: 450,

                resolve: null,
                reject: null,

                driver: null,

                isRunning: false,

                term: null,
                termInput: '',
                fitAddon: null,

                inputResolve: null,
                externalLibs: {
                    // "./mod/__init__.js": "./mod.js",
                    "./kgame/__init__.js": '/libs/mod/kgame.js',
                },

                methodGroup: [
                    {
                        name: "hero.moveDown()",
                        desc: "使hero向下（南）移动。"
                    },
                    {
                        name: "hero.moveLeft()",
                        desc: "使hero向左（西）移动。"
                    },
                    {
                        name: "hero.moveRight()",
                        desc: "使hero向右（东）移动。"
                    },
                    {
                        name: "hero.moveUp()",
                        desc: "使hero向上（北）移动。"
                    },
                ]
            }
        },
        created() {
            this.initHooker()
        },
        mounted() {
            this.initAce()
            // this.initTerminal()

            // window.onbeforeunload = function() {
            //     return "确定要离开当前页面吗？";
            // }
            window.addEventListener("beforeunload", this.actBeforeUnload, false);

            // 初始化后更新展示区窗口大小
            this.$nextTick(() => {
                this.resizePlayerArea();
            });
        },
        destroyed() {
            this.$store.commit('web/SetIsShowLoading', true);
            
            // 重置数据加载标识
            this.$store.commit('web/ResetLoadDataDone');

            // egret舞台不支持重复加载，所以不需要重置egret舞台加载标识

            // 重置角色区
            this.$store.commit('web/ResetActorAreaData');

            // 重置数据区
            this.$store.commit('web/ResetDataAreaData');
            
            // 重置数据
            CoreData.reset();

            // 重置blockly
            Ide.dispose();

            window.removeEventListener("beforeunload",this.actBeforeUnload, false);
        },
        activated() {
        },
        deactivated() {
        },
        watch: {
            // 1.等待加载数据完成
            loadDataDone: function() {
                // 加载完数据后，初始化引擎
                this.initEgret();
            },
            // 2.等待引擎加载完成
            loadStageDone: function () {
                // 加载舞台
                this.loadStage();
            },
        },
        computed: {
            ...mapGetters([
                'ide',
                'curActor',
                'isShowLoading',
                'isReset',
                'loadStageDone', 
                'loadDataDone', 
            ]),
        },
        methods: {
            insertText(content) {
                if (this.editor) {
                    let pos = this.editor.selection.getCursor();
                    if (pos.column == 0) {
                        this.editor.insert(content + "\r\n");
                    } else {
                        this.editor.gotoLine(pos.row + 2, 0)
                        this.editor.insert(content + "\r\n");
                    }
                }
            },
            initTerminal() {
                let that = this

                if (!this.term) {
                    this.term = new Terminal({
                        lineHeight: 1.3,
                        fontSize: 16,
                        cursorBlink: true, // 光标闪烁
                        cursorStyle: "block", // 光标样式  null | 'block' | 'underline' | 'bar'
                        scrollback: 800, // 回滚
                        tabStopWidth: 4, // 制表宽度
                        screenKeys: true, //
                        convertEol: true, // 回车后从最左侧开始
                        theme: {
                            // foreground: "#7e9192", //字体
                            background: "#1c1b25", //背景色
                            // cursor: "help", //设置光标
                        }
                    })

                    this.fitAddon = new FitAddon();
                    this.term.loadAddon(this.fitAddon);
                    this.term.open(document.getElementById('terminal'));
                    this.fitAddon.fit();

                    this.term.prompt = () => {
                        this.term.write('\r\n');
                    }

                    that.term.onKey(e => {
                        if (!that.isRunning) {
                            return
                        }

                        const printable = !e.domEvent.altKey && !e.domEvent.altGraphKey && !e.domEvent.ctrlKey && !e.domEvent.metaKey;

                        // 回车
                        if (e.domEvent.keyCode === 13) {

                            let input = that.termInput
                            that.term.prompt(input);
                            that.termInput = ""

                            if (that.inputResolve) {
                                that.inputResolve(input)
                            }
                            // resolve(input)

                        // Backspace
                        } else if (e.domEvent.keyCode === 8) {
                            // Do not delete the prompt
                            // if (that.term._core.buffer.x > 2) {
                                that.term.write('\b \b');
                            // }
                            that.termInput = that.termInput.substring(0, that.termInput.length - 1)

                        } else if (printable) {
                            that.termInput += e.key
                            that.term.write(e.key)
                        }
                    });
                }
            },
            initAce() {
                if (!this.editor) {
                    this.editor = ace.edit("code-editor");
                    this.editor.setTheme("ace/theme/xcode");
                    this.editor.session.setMode("ace/mode/python");
                    this.editor.session.setUseWrapMode(true);
                    this.editor.setOptions({
                        selectionStyle: "text",
                        hScrollBarAlwaysVisible: false,
                        vScrollBarAlwaysVisible: false,
                        fontSize: "18px",
                        showLineNumbers: true,
                        showFoldWidgets: true,
                        highlightActiveLine: true,
                        displayIndentGuides: false,
                        showInvisibles: false,
                        showPrintMargin: false,
                        printMargin: false,
                        printMarginColumn: false,
                        fixedWidthGutter: false,
                        wrap: true,

                        // spellcheck: true,
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                    });
                }
            },
            initHooker() {
                let that = this;

                // 注册对外接口, egret库载完成后调用
                window.setLoadStageDone = () => {
                    that.$store.commit('web/SetLoadStageDone');
                };
                // 外部接口：在引擎中选中Sprite时，同时在ActorArea选中
                window.selectSprite = (actorId) => {
                    // that.SelectSprite(actorId);
                    that.$store.dispatch('web/SelectSprite2', actorId);
                };
                window.updateHistoryStatus = (showHistoryBack, showHistoryForward) => {
                    that.showHistoryBack = showHistoryBack;
                    that.showHistoryForward = showHistoryForward;
                };
                window.autoStop = () => {
                    //that.autoStop();
                };
                window.stop = () => {
                    that.stop();
                };
                // 用于响应竖版显示时，调整窗口大小resize，根据player-area的新高度height值，同步调整宽度width
                window.ideResize = () => {
                    that.resizePlayerArea();
                };
                window.curActorId = () => {
                    return that.curActor.id;
                };
                window.isShowLoading = () => {
                    return that.isShowLoading;
                };

                // 创建变量对话框
                window.myPrompt = (title, message, varType) => {
                    return that.myPrompt(title, message, varType);
                };
                // 创建函数对话框
                window.showCreateFuncDlg = function() {
                    that.$refs.refFuncDlg.show();
                };

                // 在展示区中吸取颜色功能
                window.resetFocus = (isSelected, color) => {
                    if (that.driver) {
                        that.driver.reset();
                    }
                    that.resetFocus(isSelected, color);
                };
                window.pickColor = () => {
                    return that.pickColor();
                };
                window.updateMeta = (actorId, meta) => {
                    return;
                };
            },
            initEgret() {
                if (egret) {
                    egret.runEgret({ renderMode: "webgl", audioType: 0, calculateCanvasScaleFactor:function(context) {
                        var backingStore = context.backingStorePixelRatio ||
                                context.webkitBackingStorePixelRatio ||
                                context.mozBackingStorePixelRatio ||
                                context.msBackingStorePixelRatio ||
                                context.oBackingStorePixelRatio ||
                                context.backingStorePixelRatio || 1;
                        return (window.devicePixelRatio || 1) / backingStore;
                    }});
                }
            },
            loadStage() {
                if (this.loadStageDone && this.loadDataDone) {

                    // 清理Scratch代码区
                    // Ide.clearWorkspace();

                    // 因为toolbox命令栏中使用了egret中的数据，所以需要等到egret初始化完毕后才能init
                    // TODO: this.showToolbox 需要单独处理一下
                    // if (this.isShowLoading) {
                    //     if (CoreData.getToolbox() == '') {
                    //         Ide.init('ide');
                    //     } else {
                    //         Ide.init('classroom');
                    //         Ide.workspace.updateToolbox(CoreData.getToolbox());
                    //     }
                    // }
                    Ide.init2('ide')

                    // 重置舞台（包括展示区、角色区等）
                    this.resetStage()

                    // 先将角色重置为背景
                    this.$store.commit('web/SetCurActor', 'backdrop');

                    // 加载背景
                    let backdrop = CoreData.getCurBackdrop();
                    // egret更新
                    window.backdropInstance.loadBackdrop(backdrop);

                    // 加载背景，确保backdrop中有图片才加载
                    let costume = CoreData.getCurCostume('backdrop');
                    if (costume) {
                        // 更新角色区
                        this.$store.commit('web/AddActorAreaData', {
                            id: 'backdrop',
                            name: '背景',
                            src: getImgUrl2(costume.id)
                        });

                        // 设置当前Actor
                        this.$store.commit('web/SetCurActor', 'backdrop');

                        // 加载一遍block，用于加载老代码中的变量
                        // TODO：此处后面可以根据meta数据的版本来判断是否需要加载
                        // Ide.clearWorkspace();
                        // if (!isEmptyObject(backdrop.block)) {
                        //     Ide.block2workspace(backdrop.block);
                        // }
                    }

                    // 加载角色
                    let curData = CoreData.getCurData();
                    if (curData) {
                        let actors = []
                        
                        for (let id in curData.actors) {
                            // egret更新
                            window.spriteInstance.addSprite(CoreData.getCurActor(id), false, true);

                            // 保存角色区数据
                            let costume = CoreData.getCurCostume(id);

                            actors.push({
                                id: id,
                                name: curData.actors[id].meta.name,
                                src: getImgUrl2(costume.id),
                                index: curData.actors[id].meta.index,
                            })

                            // 设置当前Actor
                            this.$store.commit('web/SetCurActor', id);

                            // 加载一遍block，用于加载老代码中的变量
                            // TODO：此处后面可以根据meta数据的版本来判断是否需要加载
                            // Ide.clearWorkspace();
                            // // let actor = curData.actors[this.curActor.id];
                            // let actor = CoreData.getCurActor(id);
                            // if (actor) {
                            //     Ide.block2workspace(actor.block);
                            // }
                        }

                        // 更新角色区
                        actors.sort(function (a, b) {
                            return a['index'] - b['index']
                        })
                        for (let i = 0; i < actors.length; i++) {
                            actors[i].index = i
                            this.$store.commit('web/AddActorAreaData', actors[i])
                        }
                        window.stageInstance.updateSpriteIndex(actors);
                    }

                    // CoreData.paddingGlobalDatas();

                    // 同步到代码区
                    // Ide.workspace2code();

                    this.$store.commit('web/SetIsShowLoading',false);

                    // CoreData.showData();

                    // 隐藏选择框
                    window.stageInstance.hideSelector();
                    // 禁用角色选取、移动
                    window.spriteInstance.disableSelectSprite();
                }
            },
            resetStage() {
                // 停止当前运行的程序
                this.stop();

                // 重置舞台中的Sprite
                window.stageInstance.resetStage();
                
                // 更新角色区
                this.$store.commit('web/ResetActorAreaData');
            },
            // 切换舞台
            reloadStage() {
                if (this.loadStageDone && this.loadDataDone) {
                    // 重新加载舞台
                    this.loadStage();
                }
            },
            // play() {
            //     this.$store.dispatch("web/PlayerStart");
            // },
            autoStop() {
                this.$store.dispatch("web/PlayerAutoStop");
            },
            // stop() {
            //     this.$store.dispatch("web/PlayerStop");
            // },
            reset() {
                this.$store.dispatch("web/PlayerReset");
            },

            undo() {
                Ide.workspace.undo(false);
            },
            redo() {
                Ide.workspace.undo(true);
            },

            // 竖版中，更新展示区宽度，设置为高度的70%
            resizePlayerArea() {
                if (this.$refs && this.$refs.playerArea) {
                    this.leftAreaWidth = this.$refs.playerArea.offsetHeight * 1.6;
                }
            },
            toggleRuler() {
                window.stageInstance.toggleRuler();
            },
            myPrompt(title, message, varType) {
                this.$refs.refVarDlg.showDlg(title, message, varType).then(result => {
                    //console.log(result);
                    result.id = this.curActor.id;
                    this.resolve(result);
                }).catch(() => {
                    //
                });
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },

            // 展示区中选取颜色功能
            pickColor() {
                if (!this.driver) {
                    // this.driver = new Driver();
                    this.driver = new Driver({
                        className: "scoped-class", // className to wrap driver.js popover
                        animate: true, // Animate while changing highlighted element
                        opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
                        // padding: 10, // Distance of element from around the edges
                        padding: 0,
                        allowClose: false, // Whether clicking on overlay should close or not
                        overlayClickNext: false, // Should it move to next step on overlay click
                        doneBtnText: "完成", // Text on the final button
                        closeBtnText: "关闭", // Text on the close button for this step
                        nextBtnText: "下一步", // Next button text for this step
                        prevBtnText: "上一步", // Previous button text for this step
                        // Called when moving to next step on any step
                        onReset: (Element) => {
                            window.stageInstance.pickColorEnd(false);
                        },
                    });
                }
                this.driver.highlight('#player-area');

                window.stageInstance.pickColorStart();

                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            resetFocus(isSelected, color) {
                if (isSelected) {
                    this.resolve(color);
                } else {
                    this.reject();
                }
            },
            actBeforeUnload(event){
                event.returnValue = "确定要离开当前页面吗？";
            },


            // Python 相关
            outf(text) {
                console.log(text)
                if (this.term) {
                    this.term.write(text)
                }
            },
            // builtinRead(x) {
            //     if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) {
            //         throw "File not found: '" + x + "'";
            //     }

            //     // 匹配不到外部模块再从内置模块找
            //     return Sk.builtinFiles["files"][x];
            // },
            builtinRead(file) {
                /**
                 *  file参数代表当前加载的模块路径，一个模块名会按照以下6种路径和优先级查找，假设模块名为mod
                 *  src/builtin/mod.js
                 *  src/builtin/mod/__init__.js
                 *  src/lib/mod.js
                 *  src/lib/mod/__init__.js
                 *  ./mod.js
                 *  ./mod/__init__.js
                 *  前面四种路径一把是skulpt匹配自带模块用的。
                 * */
                // console.log("Attempting file: " + Sk.ffi.remapToJs(file))

                // 匹配外部模块
                if (this.externalLibs[file] !== undefined) {
                    // 使用skulpt提供的promiseToSuspension，等待异步任务执行完才能继续
                    return Sk.misceval.promiseToSuspension(
                        fetch(this.externalLibs[file]).then(
                            function (resp){ 
                                return resp.text()
                            }
                        )
                    );
                }

                if (Sk.builtinFiles === undefined || Sk.builtinFiles.files[file] === undefined) {
                    throw "File not found: '" + file + "'"
                }

                // 匹配不到外部模块再从内置模块找
                return Sk.builtinFiles.files[file];
            },
            runit() {
                let that = this

                let src = 
`
import kgame
hero = kgame.Actor("工程师")
`

                src += this.editor.getValue()

                let width = $('#turtle-area').width()
                let height = $('#turtle-area').height()

                // stop all over previous running codes
                Sk.execLimit = 0; // execution limit set to 0 will stop async

                $("#btn-stop").click(function (e) {
                    that.stop()
                });

                Sk.configure({
                    __future__: Sk.python3,
                    python3: true,
                    execLimit: Number.POSITIVE_INFINITY,
                    // execLimit: 10000,
                    output: this.outf,
                    read: this.builtinRead,
                    inputfun: (prompt) => {
                        return new Promise((resolve, reject) => {
                            that.inputResolve = resolve
                            that.term.write(prompt)
                        });
                    },
                    inputfunTakesPrompt: true,
                });
                (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "turtle-area";
                Sk.TurtleGraphics.width = width; 
                Sk.TurtleGraphics.height = height;

                var myPromise = Sk.misceval.asyncToPromise(function () {
                    that.isRunning = true;
                    return Sk.importMainWithBody("<stdin>", false, src, true);
                });
                myPromise.then(
                    function (mod) {
                        that.isRunning = false;
                        // that.term.writeln("程序结束");
                    },
                    function (err) {
                        // console.log(err.toString());

                        if (err.tp$name === "TimeLimitError" && Sk.execLimit === 0) {
                            // that.output.append('<p class="text-danger">' + "Aborted Program" + '</p>');
                            // console.log('TimeLimitError');
                        } else {
                            that.outf(err.toString() + '\r\n');
                        }
                        that.isRunning = false;
                        // this.term.writeln("程序结束");
                    }
                );
            },
            play() {
                if (this.term) {
                    this.term.clear()
                    // 将焦点放在console，便于直接输入input数据
                    this.term.focus();
                }

                this.runit();
            },
            stop() {
                // stop all over previous running codes
                Sk.execLimit = 0; // execution limit set to 0 will stop async

                this.termInput = "";
                // this.term.writeln("程序结束");

                this.isRunning = false;
            },
            clear() {
                // 停止程序
                // this.stop();
                // 清空编辑区
                if (this.editor) {
                    this.editor.setValue("");
                }
                // 清空绘图区
                $("#turtle-area").empty();
            },
        },
        components: {
            MediaLib,
            Configure,
        }
    }

</script>

<style lang="scss" scoped>
.custom-tooltip {
    opacity: 1 !important;
    padding: 0 !important;
    background: #0c0806;
}

.item-desc {
    height: 100%;
    width: 100%;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

    .desc-title {
        font-size: 16px;
        font-weight: bold;
        color: purple;
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        span {
            font-size: 13px;
            background-color: purple;
            color: #fff;
            padding: 1px 8px;
            border-radius: 4px;
            margin-left: 10px;
            margin-bottom: 2px;
        }
    }
    .desc-content {
        font-size: 15px;
        color: #333;
        letter-spacing: .5px;
    }
}

.main-area {
    width: 100%;
    height: 100%;
    display: flex;

    // 竖版样式
    &.forV {
        bottom: 110px;
        border-bottom: 3px solid #3d3f44;
    }

    .left-area {
        // width: 450px;
        width: 50%;
        //min-width: 20%;
        //max-width: 40%;
        //max-width: 600px;
        //min-width: 350px;
        //height: 100%;
        bottom: 0;
        background-color: #525357;
        display: flex;
        flex-direction: column;
        z-index: 102;
        position: relative;

        .player-area {
            height: 0;
            width: 100%;
            // padding-bottom: 62%;
            padding-bottom: 80%;
            /*宽高比70%*/
            overflow: hidden;
            position: relative;
            background: #ffffff;
            background-image:
                linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0),
                linear-gradient(45deg, #efefef 25%, transparent 0, transparent 75%, #efefef 0);
            background-position: 0 0, 15px 15px;
            background-size: 30px 30px;
            border-top: 1px solid #ea521f;

            &.forV {
                height: 100%;
                padding-bottom: unset;
            }

            .egret-player {
                margin: 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // width: 100%;
                // height: 100%;
                overflow: hidden;
                position: absolute;
            }
        }

        .cmd-area {
            width: 100%;
            height: 60px;
            min-height: 40px;
            // background-color: #404040;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;

            background: url('~@/assets/images/game/scrubber_background.png') no-repeat;
            background-size: 100% 100%;
        }
        .property-area {
            flex: 1;
            background: url('~@/assets/images/game/header_background.png') no-repeat;
            background-size: 100% 100%;
        }
    }

    .middle-area {
        width: 15%;
        height: 100%;
        background: url('~@/assets/images/game/wood_background.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .category-group {
            width: 80%;
            height: 36px;
            background: 0;
            border: 0;
            border-style: solid;
            border-image: url('~@/assets/images/game/button-background-warning-active-border.png') 14 20 20 20 fill round;
            border-width: 7px 10px 10px 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            margin-top: 10px;
            .group-title {
                color: #eee;
                font-size: 15px;
                font-weight: bold;
                margin-top: 2px;
                margin-bottom: 10px;
            }
            .group-content {
                // position: absolute;
                // top: 33px;
                width: 100%;
                font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
                font-size: 15px;
                color: #f3a931;
                background-color: #0c0806;
                cursor: pointer;
                .group-item {
                    padding: 1px 0;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

    .left-right-border {
        left: 0;
        height: 100%;
        width: 5px;
        background-color: #3d3f44;
        position: absolute;
        top: 0;
        cursor: col-resize;
        z-index: 101;

        // 竖版中不需要调整大小，直接不显示即可
        &.forV {
            height: 0;
            //bottom: 110px;
        }
    }

    /*右侧区域，包括blockly区、代码区*/
    .right-area {
        height: 100%;
        flex: 1;
        //min-width: 60%;
        //max-width: 80%;
        //width: 100%;
        //min-width: 60%;
        /*background-color: gold;*/
        background-color: #525357;

        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        /*padding-left: 10px;*/
        position: relative;

        .code-area {
            height: 100%;
            width: 100%;
            display: flex;
            // flex-direction: column;
            position: relative;

            .code-background {
                position: absolute;
                top: -50px;
                left: -10px;
                right: -10px;
                // height: 100%;
                bottom: 0;
                border: 1px solid transparent;
                border-width: 115px 76px 64px 40px;
                border-image: url('~@/assets/images/game/code_editor_background.png')  124 76 64 40 fill stretch;
                // background-image: url('~@/assets/images/game/code_editor_background.png');
            }

            .code-editor {
                // height: 100%;
                // width: 100%;
                position: absolute;
                top: 50px;
                left: 10px;
                bottom: 90px;
                right: 10px;
                line-height: 25px;
            }

            .terminal {
                height: 100%;
                // width: 100%;
                overflow: hidden;
                display: flex;
            }
        }

        .btn-area {
            position: absolute;
            left: 2px;
            right: 15px;
            bottom: 10px;
            background-color: #ea521f;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent url('~@/assets/images/game/code_toolbar_background.png');
            background-size: 100% 100%;
            padding: 20px 10px;

            .btn-run {
                height: 46px;
                width: 45%;
                border-image: url('~@/assets/images/game/code_toolbar_run_button_active.png') 14 20 20 20 fill round;
                border-width: 7px 10px 10px 10px;

                color: #f8c592;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-right: 10px;
                &:hover {
                    color: #FAD2AC;
                }
            }
            .btn-done {
                height: 46px;
                width: 45%;
                border-image: url('~@/assets/images/game/code_toolbar_submit_button_active.png') 14 20 20 20 fill round;
                border-width: 7px 10px 10px 10px;

                color: #fff;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}
</style>


<style lang="scss">
/******************************
 * 代码编辑器相关
 ******************************/

/*代码编辑器相关*/
.code-area .code-editor {
  line-height: 25px;
}

.ace_editor {
    background-color: transparent;
}
.ace_gutter {
    background-color: transparent;
}


/*优化编辑器样式配色*/
.ace-xcode {
//   background-color: #1f1e2e !important;
//   color: #CCCCCC
    color: #333;
}
.ace-xcode .ace_editor {
    background: transparent;
    background-color: transparent;
}
.ace-xcode .ace_gutter {
//   background: #1f1e2e !important;
  background: transparent;
  background-color: transparent;
  color: #8F908A !important;
}
.ace-xcode .ace_gutter-active-line {
  background-color: #ffc107;
}
.ace-xcode .ace_print-margin {
  width: 1px;
  background: #2F3129 !important;
}
.ace-xcode.ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px 0px #272822 !important;
}

.ace_scrollbar-v {
  overflow-y: hidden !important;
}
</style>




<style lang="scss">
/******************************
 * xterm 相关
 ******************************/
.xterm .xterm-viewport {
  /* On OS X this is required in order for the scroll bar to appear fully opaque */
  background-color: transparent;
  overflow-y: scroll;
  cursor: default;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  scrollbar-color: var(--highlight) var(--dark);
  scrollbar-width: thin;
}

.xterm-viewport::-webkit-scrollbar {
  background-color: var(--dark);
//   width: 5px;
  width: none;
}

.xterm-viewport::-webkit-scrollbar-thumb {
  background: var(--highlight);
}
</style>
